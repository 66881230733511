import { FC } from 'react';
import Layout from 'src/components/Layout';
import { Button } from 'src/components/ui/button';

type Props = {
  title: string;
  message: string;
};

export const ErrorPage: FC<Props> = ({ title, message }) => {
  const handleTopPageBackClick = () => {
    location.href = '/';
  };

  return (
    <Layout>
      <div className="flex h-full w-full items-center justify-center ">
        <div className="align-center w-full max-w-[640px] flex-col justify-center space-y-6  px-10">
          <h3 className="text-center text-[28px] font-bold text-brand-900">{title}</h3>
          <p className="text-center text-brand-900">{message}</p>
          <div className="text-center">
            <Button onClick={handleTopPageBackClick} variant="secondary" className="mx-auto">
              トップページに戻る
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
