import { AlertTriangle, CheckCircle } from 'lucide-react';
import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from 'src/components/ui/toast';
import { useToast } from 'src/components/ui/use-toast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex items-center">
              {props.variant === 'error' && <AlertTriangle className="mr-2 h-6 w-6" />}
              {props.variant === 'success' && <CheckCircle className="mr-2 h-6  w-6" />}
              <div className="grid gap-1">
                <ToastTitle>{title}</ToastTitle>
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
            </div>

            {action}
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}
