import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CampaignInfo } from 'src/api/main';
import { mainApi } from 'src/libs/axios';

export type Campaign = CampaignInfo;

export type CampaignState = {
  campaigns: Campaign[];
  numberOfCampaigns: number;
  numberOfPages: number;
  selectedCampaignId: number | null;
  selectedCampaign: Campaign | null;
  errorMessages: string[] | undefined;
};

const initialState: CampaignState = {
  campaigns: [],
  numberOfCampaigns: 0,
  numberOfPages: 0,
  selectedCampaignId: null,
  selectedCampaign: null,
  errorMessages: [],
};

export type CampaignsOrder = 'asc' | 'desc';

export const onGetCampaigns = createAsyncThunk<
  { campaigns: Campaign[]; numberOfCampaigns: number; numberOfPages: number },
  { pageNumber?: number }
>('campaigns', async (params) => {
  const res = await mainApi.adminCampaignsAdminGetCampaigns({
    pageNumber: params.pageNumber,
  });
  return {
    campaigns: res.data.campaigns,
    numberOfCampaigns: res.data.numberOfCampaigns,
    numberOfPages: res.data.numberOfPages,
  };
});

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(onGetCampaigns.pending, () => {});
    builder.addCase(onGetCampaigns.fulfilled, (state, action) => {
      state.campaigns = action.payload.campaigns;
      state.numberOfCampaigns = action.payload.numberOfCampaigns;
      state.numberOfPages = action.payload.numberOfPages;
    });
    builder.addCase(onGetCampaigns.rejected, () => {});
  },
});

export const campaignReducer = campaignSlice.reducer;
