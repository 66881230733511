import { ErrorResponse } from 'src/libs/axios';

export type AcceptableError = ErrorResponse;

export const isAcceptableError = (error: any): error is AcceptableError => {
  if (typeof error == 'object') {
    if ('errorMessages' in error && 'fieldErrors' in error) {
      return true;
    }
  }
  return false;
};

export const getErrorMessages = (error: any): string[] => {
  if (isAcceptableError(error)) {
    if (error.fieldErrors.length) {
      return error.fieldErrors.map((error) => error.messages).flat();
    }

    if (error.errorMessages.length) {
      return error.errorMessages;
    }
  }

  return ['予期しないエラーが発生しました'];
};
