import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { ErrorPage } from 'src/components/pages/error/ErrorPage';
import { STATUS_CODE } from 'src/constant/api';
import { ERROR_CONTENT } from 'src/constant/error';
import { isAcceptableError } from 'src/utils/errorValidate';

type Props = {
  children: ReactNode;
};

const Fallback = ({ error }: FallbackProps) => {
  const statusCode = isAcceptableError(error) ? error.statusCode ?? STATUS_CODE.SERVER_ERROR : STATUS_CODE.SERVER_ERROR;
  const errorContent = ERROR_CONTENT[statusCode] ?? ERROR_CONTENT[STATUS_CODE.SERVER_ERROR];

  return <ErrorPage title={errorContent.title} message={errorContent.message} />;
};

export const ErrorBoundaryProvider = ({ children }: Props) => {
  const router = useRouter();
  return (
    <ErrorBoundary FallbackComponent={Fallback} resetKeys={[router.pathname]}>
      {children}
    </ErrorBoundary>
  );
};
