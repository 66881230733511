import { configureStore } from '@reduxjs/toolkit';

import { adminReducer } from './slice/adminSlice';
import { campaignReducer } from './slice/campaignSlice';
import { gymAnnouncementReducer } from './slice/gymAnnouncementSlice';
import { gymReducer } from './slice/gymSlice';
import { newsReducer } from './slice/newsSlice';
import { partnerReducer } from './slice/partnerSlice';
import { planReducer } from './slice/planSlice';

export const store = configureStore({
  reducer: {
    admin: adminReducer,
    gym: gymReducer,
    campaign: campaignReducer,
    news: newsReducer,
    partners: partnerReducer,
    plans: planReducer,
    gymAnnouncements: gymAnnouncementReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
