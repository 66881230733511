import { ReactNode } from 'react';
import { cn } from 'src/libs/utils';

type Props = {
  name: string;
  isSelected?: boolean;
  icon?: ReactNode;
  onClick: () => void;
};

export const MenuItem = ({ name, isSelected = false, icon, onClick }: Props) => {
  return (
    <div
      className={cn(
        'flex cursor-pointer items-center rounded-md px-2 py-1.5 text-sm font-medium text-brand-700 hover:bg-brand-100 [&>svg]:mr-2 [&>svg]:h-4 [&>svg]:w-4',
        isSelected ? 'bg-brand-100' : 'bg-white',
      )}
      onClick={onClick}>
      {icon}
      {name}
    </div>
  );
};
