import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { Header } from 'src/components/layouts/Header';
import { Sidebar } from 'src/components/layouts/Sidebar';
import { PUBLIC_ROUTES } from 'src/constant/page';
import { cn } from 'src/libs/utils';

type Props = {
  children?: ReactNode;
  title?: string;
  drawerExists?: boolean;
};

const Layout = ({ children, title = 'LifeFit HQ Admin' }: Props) => {
  const router = useRouter();
  const isSidebarVisible = !PUBLIC_ROUTES.includes(router.pathname);

  return (
    <div>
      <Head>
        <title>{title}</title>
        <meta charSet="utf-8" />
      </Head>
      <div className="relative min-h-screen w-screen flex-col">
        <Header />
        <div className="flex space-x-10 px-8 ">
          {isSidebarVisible && <Sidebar />}
          <main className={cn('flex-1 overflow-auto pb-12 pt-8', !isSidebarVisible ? 'h-fullScreen' : '')}>
            {children}
          </main>
        </div>
        <div className="flex h-footer items-center border-t px-8 py-4">
          <p className="text-sm text-brand-500">©FiT, Inc.</p>
        </div>
      </div>
    </div>
  );
};

export default Layout;
