import axios from 'axios';
import { DefaultApiFactory } from 'src/api/main';
import { BASE_URL } from 'src/constant';
import { STATUS_CODE, TOKEN_EXPIRED_MESSAGE } from 'src/constant/api';

export type ErrorResponse = {
  errorMessages: string[];
  fieldErrors: FieldError[];
  axiosCode?: string;
  statusCode?: number;
};

export type FieldError = {
  field: string;
  messages: string[];
};

export const getErrorResponse = (errorData: any): ErrorResponse => {
  const errorResponse: ErrorResponse = {
    errorMessages: ['予期せぬエラーです'],
    fieldErrors: [],
  };

  if (!axios.isAxiosError(errorData)) {
    return errorResponse;
  }

  const axiosError = errorData;
  // axiosのエラー情報をセット
  errorResponse.axiosCode = axiosError.code;
  errorResponse.errorMessages = [axiosError.message];
  errorResponse.statusCode = axiosError.response?.status;

  const serverErrorData = axiosError.response?.data;

  if (!serverErrorData) {
    return errorResponse;
  }

  // 502だとhtmlが返されるのでチェック
  if (serverErrorData instanceof Object === false) {
    return errorResponse;
  }

  // サーバーから返されるエラーがあればaxiosのエラーを上書き

  if ('message' in serverErrorData) {
    const errorMessage = serverErrorData.message;
    errorResponse.errorMessages = ['予期せぬエラーです'];

    //TODO:messageで配列が返されないことが確認できたら単一メッセージの際はmessageにしたい

    if (errorMessage instanceof Array) {
      errorResponse.errorMessages = errorMessage;
    }
    if (typeof errorMessage == 'string') {
      errorResponse.errorMessages = [errorMessage];
    }
  }

  if ('errors' in serverErrorData) {
    errorResponse.fieldErrors = serverErrorData.errors;
  }

  if ('statusCode' in serverErrorData) {
    errorResponse.statusCode = serverErrorData.statusCode;
  }

  return errorResponse;
};

export const axiosInstance = axios.create({
  headers: {
    'content-type': 'application/json',
  },
});

export const mainApi = DefaultApiFactory(undefined, BASE_URL, axiosInstance);

export const createAuthorizationHeaderValue = (token: string) => `Bearer ${token}`;

export const setAxiosAuthorizationHeader = (token: string) => {
  axiosInstance.defaults.headers.common['Authorization'] = createAuthorizationHeaderValue(token);
};

export const removeAxiosAuthorizationHeader = () => {
  axiosInstance.defaults.headers.common['Authorization'] = '';
};

export const isUnauthorizedError = (errorResponse: ErrorResponse) => {
  return errorResponse.statusCode === STATUS_CODE.UNAUTHORIZED;
};

export const isTokenExpiredError = (errorResponse: ErrorResponse) => {
  return errorResponse.errorMessages.includes(TOKEN_EXPIRED_MESSAGE);
};

export const isForbiddenError = (errorResponse: ErrorResponse) => {
  return errorResponse.statusCode === STATUS_CODE.FORBIDDEN;
};

export const isServerError = (errorResponse: ErrorResponse) => {
  return errorResponse.statusCode === STATUS_CODE.SERVER_ERROR;
};

export const isNotFoundError = (errorResponse: ErrorResponse) => {
  return errorResponse.statusCode === STATUS_CODE.NOT_FOUND;
};
