import { STATUS_CODE } from 'src/constant/api';

type ErrorContent = Record<number, { title: string; message: string }>;
export const ERROR_CONTENT: ErrorContent = {
  [STATUS_CODE.FORBIDDEN]: {
    title: 'アクセスが拒否されました',
    message: 'このページにアクセスするための十分な権限を持っていません。',
  },
  [STATUS_CODE.NOT_FOUND]: {
    title: 'ページが見つかりませんでした',
    message: 'お探しのページはURLが間違っているか、削除された可能性があります。',
  },
  [STATUS_CODE.SERVER_ERROR]: {
    title: 'サーバーエラーが発生しました',
    message: 'しばらくしてから再度お試しください。',
  },
};
