import { datadogRum } from '@datadog/browser-rum';
import { DATADOG_ENV, ENV_STATE } from 'src/constant';

const envForDataDog = DATADOG_ENV === ENV_STATE.production || DATADOG_ENV === ENV_STATE.staging;

export const initDataDog = () => {
  if (envForDataDog) {
    datadogRum.init({
      applicationId: 'f16e77f6-d786-448e-b12b-338cb3d692c0',
      clientToken: 'pubd792f57c8334c79335bffd101f1a0862',
      site: 'ap1.datadoghq.com',
      service: 'admin',
      env: DATADOG_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 80,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
};

export const dataDogSetUser = (userId: number) => {
  if (envForDataDog) {
    datadogRum.setUser({ userId: userId.toString() });
  }
};

export const dataDogClearUser = () => {
  if (envForDataDog) {
    datadogRum.clearUser();
  }
};
