import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreateNewsRequestTransitionPathEnum,
  CreateNewsRequestTypeEnum,
  CreateNewsResponse,
  NewsInfo,
  NewsInfoTypeEnum,
  SearchNewsRequestTypeEnum,
} from 'src/api/main';
import { mainApi } from 'src/libs/axios';
import { AcceptableErrorConfig } from 'src/utils/error';
import { apiErrorHandling } from 'src/utils/errorHandling';
import { newsOrderEnumConverter } from 'src/utils/orderEnumConverter';

export type NewsState = {
  newsList: NewsInfo[];
  selectedNews: NewsInfo;
  numberOfNews: number;
  numberOfPages: number;
  errorMsg: string[];
};

const initialState: NewsState = {
  newsList: [],
  selectedNews: {
    id: 0,
    type: NewsInfoTypeEnum.Admin,
    title: '',
    content: '',
    pictureUrl: '',
    logoUrl: '',
    sender: '',
    read: false,
    registerDatetime: '',
    transitionUrl: '',
  },
  numberOfNews: 0,
  numberOfPages: 0,
  errorMsg: [],
};

export type NewsOrder = 'asc' | 'desc';
export type NewsType = 'Admin' | 'Brand' | 'Gym';
export type NewsDropdown = 'Admin' | 'Brand' | 'Gym' | 'All';

const convertNewsTypeToCreate = (newsType: NewsType) => {
  switch (newsType) {
    case 'Admin':
      return CreateNewsRequestTypeEnum.Admin;
    case 'Brand':
      return CreateNewsRequestTypeEnum.Brand;
    case 'Gym':
      return CreateNewsRequestTypeEnum.Gym;
  }
};

const convertNewsTypeToSearch = (newsType: NewsType) => {
  switch (newsType) {
    case 'Admin':
      return SearchNewsRequestTypeEnum.Admin;
    case 'Brand':
      return SearchNewsRequestTypeEnum.Brand;
    case 'Gym':
      return SearchNewsRequestTypeEnum.Gym;
  }
};

export const onGetNews = createAsyncThunk<
  { news: NewsInfo[]; numberOfNews: number; numberOfPages: number },
  {
    filter: {
      page: number;
      OR: boolean;
      title: string | undefined;
      postDatetime: string | undefined;
      type: NewsDropdown;
      order: NewsOrder;
    };
  }
>('news/onGetNews', async (params) => {
  const order = newsOrderEnumConverter(params.filter.order);
  const res = await mainApi.newsAdminGetUserByFilter({
    pageNumber: params.filter.page,
    OR: params.filter.OR,
    title: params.filter.title ? params.filter.title : undefined,
    type: params.filter.type != 'All' ? convertNewsTypeToSearch(params.filter.type) : undefined,
    postDatetime: params.filter.postDatetime ? params.filter.postDatetime : undefined,
    order,
  });

  return {
    news: res.data.news,
    numberOfNews: res.data.numberOfNews,
    numberOfPages: res.data.numberOfPages,
  };
});

export const createNews = createAsyncThunk<
  CreateNewsResponse,
  {
    type: NewsType;
    typeId?: number;
    title: string;
    content: string;
    pictureUrl?: string;
    enablePushNotification?: boolean;
    transitionPath?: CreateNewsRequestTransitionPathEnum;
  },
  AcceptableErrorConfig
>('news/createNews', async (params, thunkApi) => {
  try {
    const res = await mainApi.newsCreateNews({
      type: convertNewsTypeToCreate(params.type),
      typeId: params.typeId ?? 0,
      title: params.title,
      content: params.content,
      pictureUrl: params.pictureUrl,
      enablePushNotification: params.enablePushNotification,
      transitionPath: params.transitionPath,
    });

    if (!res.data.result) {
      return thunkApi.rejectWithValue(res.data.errorMessages);
    }

    return res.data;
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setSelectedNews: (state, action: PayloadAction<NewsInfo>) => {
      state.selectedNews = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNews.pending, (state) => {
      state.errorMsg = [];
    });
    builder.addCase(createNews.fulfilled, () => {});
    builder.addCase(createNews.rejected, (state, action) => {
      state.errorMsg = action.payload ?? ['Something Went Wrong'];
    });
    builder.addCase(onGetNews.pending, () => {});
    builder.addCase(onGetNews.fulfilled, (state, action) => {
      state.newsList = action.payload.news;
      state.numberOfNews = action.payload.numberOfNews;
      state.numberOfPages = action.payload.numberOfPages;
    });
    builder.addCase(onGetNews.rejected, () => {});
  },
});

export const newsReducer = newsSlice.reducer;
