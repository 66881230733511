import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { isLoggedInSelector } from 'src/store/selector/adminSelector';

export const Header = () => {
  const isLoggedIn = useSelector(isLoggedInSelector);
  const router = useRouter();

  const handleTitleClick = () => {
    if (isLoggedIn) {
      router.push('/');
      return;
    }
  };

  return (
    <div className="sticky top-0 z-50 w-screen">
      <div className="h-headerTop  bg-white px-8 py-4">
        <p className="inline cursor-pointer  font-header text-xl font-bold text-brand-700" onClick={handleTitleClick}>
          LifeFit Dashboard
        </p>
      </div>
      <div className="h-headerBottom bg-amber-500 py-2 text-center">
        <p className="text-sm text-white">HQ 管理画面</p>
      </div>
    </div>
  );
};
