import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CreatePlanRequestAvailabilityTypeEnum,
  CreatePlanRequestPlanTypeEnum,
  CreatePlanRequestTermUnitEnum,
  SubscribePlan,
  SubscribePlanAvailabilityTypeEnum,
  SubscribePlanPlanTypeEnum,
  SubscribePlanTermUnitEnum,
  UpdatePlanRequestAvailabilityTypeEnum,
  UpdatePlanRequestPlanTypeEnum,
  UpdatePlanRequestTermUnitEnum,
} from 'src/api/main';
import { DropdownObj } from 'src/components/atoms/CustomDropdown';
import { mainApi } from 'src/libs/axios';
import { AcceptableErrorConfig } from 'src/utils/error';
import { apiErrorHandling } from 'src/utils/errorHandling';

export type PlanState = {
  subscribePlans: SubscribePlan[];
  selectedSubscribePlan: SubscribePlan;
  loading: boolean;
  creatingCompleted: boolean;
  editingCompleted: boolean;
  errors: string[];
};

export type PlanType = keyof typeof SubscribePlanPlanTypeEnum;
export type PlanAvailabilityType = keyof typeof SubscribePlanAvailabilityTypeEnum;
export type PlanTermUnit = keyof typeof SubscribePlanTermUnitEnum;

//planのドロップダウン
export const PLAN_TYPE_OPTIONS = [
  {
    value: 'Standard',
    label: '通常',
  },
  {
    value: 'Organization',
    label: '組織',
  },
];

export const AVAILABILITY_TYPE_OPTIONS = [
  {
    value: 'All',
    label: '全ジム利用可能',
  },
  {
    value: 'Specific',
    label: '特定のジムのみ利用可能',
  },
];

export const TERM_UNIT_OPTIONS = [
  {
    value: 'Day',
    label: '日',
  },
  {
    value: 'Month',
    label: '月',
  },
];

export const PLAN_TYPE_TEXT: Record<PlanType, string> = {
  [SubscribePlanPlanTypeEnum.Standard]: '通常',
  [SubscribePlanPlanTypeEnum.Organization]: '組織',
};

export const AVAILABILITY_TYPE_TEXT: Record<PlanAvailabilityType, string> = {
  [SubscribePlanAvailabilityTypeEnum.All]: '全ジム利用可能',
  [SubscribePlanAvailabilityTypeEnum.Specific]: '特定のジムのみ利用可能',
};

export const TERM_UNIT_TEXT: Record<PlanTermUnit, string> = {
  [SubscribePlanTermUnitEnum.Day]: '日',
  [SubscribePlanTermUnitEnum.Month]: '月',
};

export const curateSubscribePlanDropdowns = (
  subscribePlans: SubscribePlan[],
): { allPlansDropdown: DropdownObj[]; standardPlansDropdown: DropdownObj[] } => {
  const allPlansDropdown = subscribePlans.map((plan) => {
    return {
      value: {
        ...plan,
        planType: PLAN_TYPE_TEXT[plan.planType],
        availabilityType: AVAILABILITY_TYPE_TEXT[plan.availabilityType],
        termUnit: TERM_UNIT_TEXT[plan.termUnit],
      },
      name: plan.name,
    };
  });

  const standardPlansDropdown = allPlansDropdown.filter((e) => e.value.planType === '通常');

  return { allPlansDropdown, standardPlansDropdown };
};

const initialState: PlanState = {
  subscribePlans: [],
  selectedSubscribePlan: {
    id: 0,
    name: '',
    subtitle: '',
    planType: SubscribePlanPlanTypeEnum.Standard,
    availabilityType: SubscribePlanAvailabilityTypeEnum.All,
    explain: '',
    termUnit: SubscribePlanTermUnitEnum.Day,
    term: 0,
    price: 0,
  },
  loading: false,
  creatingCompleted: false,
  editingCompleted: false,
  errors: [],
};

export const createNewPlan = createAsyncThunk<
  {
    result: boolean;
    errorMsg: string[];
  },
  {
    name: string;
    subtitle: string;
    planType: PlanType;
    availabilityType: PlanAvailabilityType;
    explain?: string;
    price: number;
    termUnit: PlanTermUnit;
    term: number;
  },
  AcceptableErrorConfig
>('plan/createNewPlan', async (params, thunkApi): Promise<any> => {
  try {
    const res = await mainApi.adminSubscribePlansCreatePlan({
      name: params.name,
      subtitle: params.subtitle,
      planType: params.planType as CreatePlanRequestPlanTypeEnum,
      availabilityType: params.availabilityType as CreatePlanRequestAvailabilityTypeEnum,
      explain: params.explain,
      price: params.price,
      termUnit: params.termUnit as CreatePlanRequestTermUnitEnum,
      term: params.term,
      useFirstTimePlan: false,
    });

    return {
      subscribePlan: res.data.subscribePlan,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const editPlan = createAsyncThunk<
  {
    result: boolean;
    errorMsg: string[];
  },
  {
    id: number;
    name: string;
    subtitle: string;
    planType: PlanType;
    availabilityType: PlanAvailabilityType;
    explain?: string;
    termUnit: PlanTermUnit;
    term: number;
    price: number;
  },
  AcceptableErrorConfig
>('plan/editPlan', async (params, thunkApi): Promise<any> => {
  try {
    const res = await mainApi.adminSubscribePlansUpdatePlan(params.id, {
      name: params.name,
      subtitle: params.subtitle,
      planType: params.planType as UpdatePlanRequestPlanTypeEnum,
      availabilityType: params.availabilityType as UpdatePlanRequestAvailabilityTypeEnum,
      explain: params.explain,
      termUnit: params.termUnit as UpdatePlanRequestTermUnitEnum,
      term: params.term,
      price: params.price,
      useFirstTimePlan: false,
    });

    return {
      subscribePlan: res.data.subscribePlan,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const getSubscribePlan = createAsyncThunk<
  { subscribePlan: SubscribePlan },
  {
    id: number;
  }
>('plan/getPartnerInfo', async (params) => {
  const res = await mainApi.adminSubscribePlansGetSubscribePlan(params.id);

  return {
    subscribePlan: res.data.subscribePlan,
  };
});

//TODO: usePlansで置き換える
export const getAllSubscribePlans = createAsyncThunk<{ subscribePlans: SubscribePlan[] }>(
  'plan/getSubscribePlans',
  async () => {
    const res = await mainApi.adminSubscribePlansGetAllSubscribePlans(undefined, undefined);

    return {
      subscribePlans: res.data.subscribePlans,
    };
  },
);

export const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {
    setCreatingComplete: (state, action: PayloadAction<boolean>) => {
      state.creatingCompleted = action.payload;
    },
    setEditingComplete: (state, action: PayloadAction<boolean>) => {
      state.editingCompleted = action.payload;
    },
    resetErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSubscribePlan.pending, () => {});
    builder.addCase(getSubscribePlan.fulfilled, (state, action) => {
      state.selectedSubscribePlan = action.payload.subscribePlan;
    });
    builder.addCase(getSubscribePlan.rejected, () => {});
    builder.addCase(getAllSubscribePlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllSubscribePlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllSubscribePlans.fulfilled, (state, action) => {
      state.subscribePlans = action.payload.subscribePlans;
      state.loading = false;
    });
    builder.addCase(createNewPlan.fulfilled, (state) => {
      state.loading = false;
      state.creatingCompleted = true;
      state.errors = [];
    });
    builder.addCase(createNewPlan.pending, (state) => {
      state.loading = true;
      state.creatingCompleted = false;
      state.errors = [];
    });
    builder.addCase(createNewPlan.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = [...action.payload];
    });
    builder.addCase(editPlan.fulfilled, (state) => {
      state.loading = false;
      state.editingCompleted = true;
      state.errors = [];
    });
    builder.addCase(editPlan.pending, (state) => {
      state.loading = true;
      state.editingCompleted = false;
      state.errors = [];
    });
    builder.addCase(editPlan.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.editingCompleted = false;
      state.errors = [...action.payload];
    });
  },
});

export const planReducer = planSlice.reducer;
