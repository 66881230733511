import { SearchNewsRequestOrderEnum } from 'src/api/main';
import { NewsOrder } from 'src/store/slice/newsSlice';

export const newsOrderEnumConverter = (order: NewsOrder): SearchNewsRequestOrderEnum => {
  switch (order) {
    case 'asc':
      return SearchNewsRequestOrderEnum.Asc;
    case 'desc':
      return SearchNewsRequestOrderEnum.Desc;
    default:
      return SearchNewsRequestOrderEnum.Asc;
  }
};
