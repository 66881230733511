import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PartnerDisplayInfo, PartnerDisplayInfoPage } from 'src/api/main';
import { mainApi } from 'src/libs/axios';
import { AcceptableErrorConfig } from 'src/utils/error';
import { apiErrorHandling } from 'src/utils/errorHandling';

export type PartnerState = {
  SelectedPartner: PartnerDisplayInfoPage;
  Partners: PartnerDisplayInfo[];
  loading: boolean;
  creatingCompleted: boolean;
  editingCompleted: boolean;
  errors: string[];
};

const initialState: PartnerState = {
  SelectedPartner: {
    id: 0,
    name: '',
    email: '',
    gyms: [],
  },
  Partners: [],
  loading: false,
  creatingCompleted: false,
  editingCompleted: false,
  errors: [],
};

export const getSelectedPartner = createAsyncThunk<
  { partner: PartnerDisplayInfoPage },
  {
    partnerId: number;
  }
>('gym/getPartnerInfo', async (params) => {
  const res = await mainApi.adminPartnersGetPartnerInfo(params.partnerId.toString());
  return {
    partner: res.data.partner,
  };
});

export const getAllPartners = createAsyncThunk<{ partners: PartnerDisplayInfo[] }>('gym/getAllPartners', async () => {
  const res = await mainApi.adminPartnersGetAllPartners();

  return {
    partners: res.data.partners,
  };
});

export const createNewPartner = createAsyncThunk<
  {
    result: boolean;
    errorMsg: string[];
  },
  {
    name: string;
    email: string;
    password: string;
  },
  AcceptableErrorConfig
>('gym/createNewPartners', async (params, thunkApi): Promise<any> => {
  try {
    const res = await mainApi.adminPartnersCreateNewPartner({
      name: params.name,
      email: params.email,
      password: params.password,
    });

    return {
      result: res.data.result,
      errorMsg: res.data.errorMessages,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const editPartner = createAsyncThunk<
  {
    result: boolean;
    errorMsg: string[];
  },
  {
    partnerId: number;
    name?: string;
    email?: string;
    password?: string;
  },
  AcceptableErrorConfig
>('gym/editPartners', async (params, thunkApi): Promise<any> => {
  try {
    const res = await mainApi.adminPartnersEditPartner({
      id: params.partnerId,
      name: params.name,
      email: params.email,
      password: params.password,
    });

    return {
      result: res.data.result,
      errorMsg: res.data.errorMessages,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setEditingComplete: (state, action: PayloadAction<boolean>) => {
      state.editingCompleted = action.payload;
    },
    resetErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSelectedPartner.pending, () => {});
    builder.addCase(getSelectedPartner.fulfilled, (state, action) => {
      state.SelectedPartner = action.payload.partner;
    });
    builder.addCase(getSelectedPartner.rejected, () => {});
    builder.addCase(getAllPartners.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPartners.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllPartners.fulfilled, (state, action) => {
      state.loading = false;
      state.Partners = action.payload.partners;
    });
    builder.addCase(editPartner.fulfilled, (state) => {
      state.loading = false;
      state.editingCompleted = true;
    });
    builder.addCase(editPartner.pending, (state) => {
      state.loading = true;
      state.editingCompleted = false;
      state.errors = [];
    });
    builder.addCase(editPartner.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    });
    builder.addCase(createNewPartner.fulfilled, (state) => {
      state.loading = false;
      state.creatingCompleted = true;
    });
    builder.addCase(createNewPartner.pending, (state) => {
      state.loading = true;
      state.creatingCompleted = false;
      state.errors = [];
    });
    builder.addCase(createNewPartner.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    });
  },
});

export const partnerReducer = partnerSlice.reducer;
