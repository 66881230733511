import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CreateGymAnnouncementRequestStatusEnum,
  GymAnnouncement,
  UpdateGymAnnouncementRequestStatusEnum,
} from '../../api/main';
import { mainApi } from '../../libs/axios';
import { AcceptableErrorConfig } from '../../utils/error';
import { apiErrorHandling } from '../../utils/errorHandling';

export type GymAnnouncementState = {
  announcements: GymAnnouncement[];
  loading: boolean;
  creatingCompleted: boolean;
  editingCompleted: boolean;
  errors: string[];
};

const initialState: GymAnnouncementState = {
  announcements: [],
  loading: false,
  creatingCompleted: false,
  editingCompleted: false,
  errors: [],
};

export const getGymAnnouncements = createAsyncThunk<{ announcements: GymAnnouncement[] }, { gymId: number }>(
  'gym/getGymAnnouncements',
  async (params) => {
    const res = await mainApi.gymGetGymAnnouncements(params.gymId);

    return {
      announcements: res.data.announcements,
    };
  },
);

export const createGymAnnouncement = createAsyncThunk<
  {
    result: boolean;
    errorMessages: string[];
  },
  {
    gymId: number;
    content: string;
    status: CreateGymAnnouncementRequestStatusEnum;
  },
  AcceptableErrorConfig
>('gym/createGymAnnouncement', async (params, thunkApi): Promise<any> => {
  try {
    const { data } = await mainApi.gymCreateGymAnnouncement(params.gymId, {
      content: params.content,
      status: params.status,
    });

    return {
      result: data,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const changeGymAnnouncementStatus = createAsyncThunk<
  {
    result: boolean;
    errorMessages: string[];
  },
  {
    id: number;
    gymId: number;
    status: UpdateGymAnnouncementRequestStatusEnum;
  },
  AcceptableErrorConfig
>('gym/archiveGymAnnouncement', async (params, thunkApi): Promise<any> => {
  try {
    const { data } = await mainApi.gymUpdateGymAnnouncement(params.gymId, params.id, {
      status: params.status,
    });

    return {
      result: data,
    };
  } catch (e) {
    return apiErrorHandling(e, thunkApi.rejectWithValue);
  }
});

export const gymAnnouncementSlice = createSlice({
  name: 'gymAnnouncement',
  initialState,
  reducers: {
    setEditingComplete: (state, action: PayloadAction<boolean>) => {
      state.editingCompleted = action.payload;
    },
    resetErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGymAnnouncements.pending, (state) => {
      state.loading = true;
      state.errors = [];
    });
    builder.addCase(getGymAnnouncements.fulfilled, (state, action) => {
      state.loading = false;
      state.announcements = action.payload.announcements;
    });
    builder.addCase(getGymAnnouncements.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    });
    builder.addCase(createGymAnnouncement.pending, (state) => {
      state.loading = true;
      state.creatingCompleted = false;
      state.errors = [];
    });
    builder.addCase(createGymAnnouncement.fulfilled, (state) => {
      state.loading = false;
      state.creatingCompleted = true;
    });
    builder.addCase(createGymAnnouncement.rejected, (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.errors = [...state.errors, action.payload];
    });
  },
});

export const gymAnnouncementReducer = gymAnnouncementSlice.reducer;
