import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BrandInfo, EquipmentInfo, HQGymForList, OptionPlanInfo, ShotPlanDisplayInfo } from 'src/api/main';
import { mainApi } from 'src/libs/axios';

export type GymState = {
  GymList: HQGymForList[];
  BrandList: BrandInfo[];
  OptionPlans: OptionPlanInfo[];
  ShotPlans: ShotPlanDisplayInfo[];
  Equipments: EquipmentInfo[];
  loading: boolean;
  creatingCompleted: boolean;
  editingCompleted: boolean;
  errors: string[];
};

const initialState: GymState = {
  GymList: [],
  BrandList: [],
  OptionPlans: [],
  ShotPlans: [],
  Equipments: [],
  loading: false,
  creatingCompleted: false,
  editingCompleted: false,
  errors: [],
};

export const getAllGyms = createAsyncThunk<{ gyms: HQGymForList[] }>('gym/getAllGyms', async () => {
  const res = await mainApi.adminGymsGetAllGyms();
  return {
    gyms: res.data.gyms,
  };
});

export const getAllOptionPlans = createAsyncThunk<{ optionPlans: OptionPlanInfo[] }>('gym/getOptionPlans', async () => {
  const res = await mainApi.adminGymsGetAllOptionPlans();

  return {
    optionPlans: res.data.optionPlans,
  };
});

export const getAllShotPlans = createAsyncThunk<{ shotPlans: ShotPlanDisplayInfo[] }>('gym/getShotPlans', async () => {
  const res = await mainApi.adminGymsGetAllShotPlans();

  return {
    shotPlans: res.data.shotPlans,
  };
});

export const getAllEquipment = createAsyncThunk<{ equipments: EquipmentInfo[] }>('gym/getAllEquipments', async () => {
  const res = await mainApi.adminGymsGetAllEquipments();

  return {
    equipments: res.data.equipments,
  };
});

export const getAllBrands = createAsyncThunk<{ brands: BrandInfo[] }>('gym/getAllBrands', async () => {
  const res = await mainApi.adminGymsGetAllBrands();

  return {
    brands: res.data.brands,
  };
});

export const gymSlice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    setEditingComplete: (state, action: PayloadAction<boolean>) => {
      state.editingCompleted = action.payload;
    },
    resetErrors: (state) => {
      state.errors = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllGyms.pending, () => {});
    builder.addCase(getAllGyms.fulfilled, (state, action) => {
      state.GymList = action.payload.gyms;
    });
    builder.addCase(getAllGyms.rejected, () => {});
    builder.addCase(getAllBrands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBrands.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllBrands.fulfilled, (state, action) => {
      state.BrandList = action.payload.brands;
      state.loading = false;
    });
    builder.addCase(getAllOptionPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllOptionPlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllOptionPlans.fulfilled, (state, action) => {
      state.OptionPlans = action.payload.optionPlans;
      state.loading = false;
    });
    builder.addCase(getAllShotPlans.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllShotPlans.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllShotPlans.fulfilled, (state, action) => {
      state.loading = false;
      state.ShotPlans = action.payload.shotPlans;
    });
    builder.addCase(getAllEquipment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllEquipment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAllEquipment.fulfilled, (state, action) => {
      state.loading = false;
      state.Equipments = action.payload.equipments;
    });
  },
});

export const gymReducer = gymSlice.reducer;
