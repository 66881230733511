import 'styles/globals.css';

import type { AppProps } from 'next/app';
import { Noto_Sans_JP } from 'next/font/google';
import { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Toaster } from 'src/components/ui/toaster';
import { cn } from 'src/libs/utils';
import { AuthProvider } from 'src/providers/AuthProvider';
import { AxiosProvider } from 'src/providers/AxiosProvider';
import { ErrorBoundaryProvider } from 'src/providers/ErrorBoundaryProvider';
import { initDataDog } from 'src/resources/dataDogHelpers';
import { store } from 'src/store/store';

export const fontSans = Noto_Sans_JP({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  variable: '--font-noto-sans-jp',
});

// eslint-disable-next-line @typescript-eslint/naming-convention
const App = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    initDataDog();
  }, []);

  return (
    <Provider store={store}>
      <ErrorBoundaryProvider>
        <AxiosProvider>
          <AuthProvider>
            <main className={cn('font-sans ', fontSans.variable)}>
              <Component {...pageProps} />
            </main>
            <Toaster />
          </AuthProvider>
        </AxiosProvider>
      </ErrorBoundaryProvider>
    </Provider>
  );
};

export default App;
