import { AxiosError, AxiosRequestConfig } from 'axios';
import { FC, ReactNode, useCallback, useEffect } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import { useToast } from 'src/components/ui/use-toast';
import { useLogout } from 'src/hooks/useAuth';
import { usePostRefreshToken } from 'src/hooks/usePostRefreshToken';
import {
  axiosInstance,
  createAuthorizationHeaderValue,
  getErrorResponse,
  isForbiddenError,
  isNotFoundError,
  isServerError,
  isTokenExpiredError,
  isUnauthorizedError,
  setAxiosAuthorizationHeader,
} from 'src/libs/axios';
import { accessTokenStorage, refreshTokenStorage } from 'src/utils/localStorage';

interface Props {
  children: ReactNode;
}

export interface RetryQueueItem {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
  config: AxiosRequestConfig;
}

// 同時に複数のリクエストが401エラーを返した場合に、リフレッシュトークンを複数回リクエストしないようにするためのキュー
const refreshAndRetryQueue: RetryQueueItem[] = [];

// リフレッシュトークンリクエスト中かどうかのフラグ
let isRefreshing = false;

export const AxiosProvider: FC<Props> = ({ children }) => {
  const { showBoundary } = useErrorBoundary();
  const { postRefreshToken } = usePostRefreshToken();
  const { clearLoginInfo } = useLogout();
  const { toastError } = useToast();

  const handleUnAuthError = useCallback(() => {
    clearLoginInfo();
    toastError({
      title: '再ログインしてください',
    });
  }, [clearLoginInfo, toastError]);

  useEffect(() => {
    //interceptor登録
    const responseInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        //成功時
        return response;
      },
      async (err: AxiosError) => {
        //失敗時
        const originalRequest = err.config;
        const errorResponse = getErrorResponse(err);
        const refreshToken = refreshTokenStorage.get();

        // こちらの記事を参考に実装
        // https://medium.com/@sina.alizadeh120/repeating-failed-requests-after-token-refresh-in-axios-interceptors-for-react-js-apps-50feb54ddcbc
        if (isUnauthorizedError(errorResponse)) {
          // refreshの条件が揃っていない場合はログアウト
          if (!originalRequest || !refreshToken || !isTokenExpiredError(errorResponse)) {
            handleUnAuthError();
            return Promise.reject(errorResponse);
          }

          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const res = await postRefreshToken({ refreshToken });
              isRefreshing = false;

              // リトライキューに追加されたリクエストのAuthorizationを新たなtokenで更新した上で再実行
              refreshAndRetryQueue.forEach(async ({ config, resolve, reject }) => {
                await axiosInstance
                  .request({
                    ...config,
                    headers: { ...config.headers, Authorization: createAuthorizationHeaderValue(res.data.accessToken) },
                  })
                  .then((response) => resolve(response))
                  .catch((err) => reject(err));
              });

              // リトライキューをクリア
              refreshAndRetryQueue.length = 0;

              // 新たなaccessTokenとrefreshTokenをset
              setAxiosAuthorizationHeader(res.data.accessToken);
              accessTokenStorage.set(res.data.accessToken);
              refreshTokenStorage.set(res.data.refreshToken);

              // リフレッシュトークンリクエスト後に元のリクエストを再実行
              originalRequest.headers['Authorization'] = createAuthorizationHeaderValue(res.data.accessToken);
              return axiosInstance.request(originalRequest);
            } catch {
              handleUnAuthError();
            }
          }

          // 他リクエストでリフレッシュトークンリクエスト中の場合はリトライキューに追加
          return new Promise<void>((resolve, reject) => {
            refreshAndRetryQueue.push({
              config: originalRequest,
              resolve,
              reject,
            });
          });
        }

        if (isForbiddenError(errorResponse) || isServerError(errorResponse) || isNotFoundError(errorResponse)) {
          showBoundary(errorResponse);
        }

        return Promise.reject(errorResponse);
      },
    );
    return () => {
      //interceptor解除
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, [postRefreshToken, clearLoginInfo, handleUnAuthError, showBoundary]);

  return <>{children}</>;
};
