import { Loader2 } from 'lucide-react';
import { FC } from 'react';
type Props = {
  isLoading: boolean;
};

export const Loading: FC<Props> = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Loader2 className="mr-2 h-4 w-4 animate-spin text-brand-500" />
      <p className="text-brand-500">Loading</p>
    </div>
  );
};
