import { useRouter } from 'next/router';
import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { FullLoading } from 'src/components/loading/FullLoading';
import { useInitAuth } from 'src/hooks/useAuth';
import { isLoggedInSelector } from 'src/store/selector/adminSelector';

interface Props {
  children: ReactNode;
}

/**
 * 認証状態の初期化を行う。取得まではchildrenを表示しない
 */
export const AuthProvider: FC<Props> = ({ children }) => {
  useInitAuth();
  const isLoggedIn = useSelector(isLoggedInSelector);

  const router = useRouter();

  const shouldRedirect = isLoggedIn === false && router.pathname !== '/auth/login';

  if (shouldRedirect) {
    router.push('/auth/login');
    return null;
  }

  if (isLoggedIn === null) {
    return <FullLoading isLoading />;
  }

  return <>{children}</>;
};
