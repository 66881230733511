import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LoginRequest } from 'src/api/main';
import { useToast } from 'src/components/ui/use-toast';
import { usePostLogin } from 'src/hooks/usePostLogin';
import { usePostLogout } from 'src/hooks/usePostLogout';
import { removeAxiosAuthorizationHeader, setAxiosAuthorizationHeader } from 'src/libs/axios';
import { extractTokenInfo } from 'src/models/admin/function';
import { dataDogClearUser, dataDogSetUser } from 'src/resources/dataDogHelpers';
import { adminSlice } from 'src/store/slice/adminSlice';
import { getErrorMessages } from 'src/utils/errorValidate';
import { accessTokenStorage, refreshTokenStorage } from 'src/utils/localStorage';

export const useInitAuth = () => {
  const dispatch = useDispatch();
  const { setAuthInfo } = useLogin();
  const { clearLoginInfo } = useLogout();

  useEffect(() => {
    const accessToken = accessTokenStorage.get();
    if (accessToken) {
      setAuthInfo(accessToken);
    } else {
      clearLoginInfo();
    }
  }, [dispatch, clearLoginInfo, setAuthInfo]);
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const { toastError, toastSuccess } = useToast();
  const { postLogout } = usePostLogout();
  const router = useRouter();

  const handleLogout = async () => {
    try {
      await postLogout();
      clearLoginInfo();
      toastSuccess({
        title: 'ログアウトしました。',
      });
      router.push('/auth/login');
    } catch {
      toastError({
        title: 'ログアウトに失敗しました。',
      });
    }
  };

  const clearLoginInfo = () => {
    dispatch(adminSlice.actions.reset());
    accessTokenStorage.remove();
    refreshTokenStorage.remove();
    dataDogClearUser();
    removeAxiosAuthorizationHeader();
  };

  return { clearLoginInfo, handleLogout };
};

export const useLogin = () => {
  const { toastError, toastSuccess } = useToast();
  const dispatch = useDispatch();
  const router = useRouter();
  const { postLogin } = usePostLogin();

  const handleLogin = async (params: LoginRequest) => {
    try {
      const res = await postLogin(params);
      accessTokenStorage.set(res.data.accessToken);
      refreshTokenStorage.set(res.data.refreshToken);
      setAuthInfo(res.data.accessToken);
      toastSuccess({
        title: 'ログインしました。',
      });
      router.push(`/`);
    } catch (err) {
      const errorMessages = getErrorMessages(err);
      const errorDescription = errorMessages.join('\n');
      toastError({
        title: 'ログインに失敗しました。',
        description: errorDescription,
      });
    }
  };

  const setAuthInfo = (accessToken: string) => {
    setAxiosAuthorizationHeader(accessToken);
    dataDogSetUser(extractTokenInfo(accessToken).sub);
    dispatch(adminSlice.actions.loggedIn({ accessToken }));
  };

  return { handleLogin, setAuthInfo };
};
