import { FC } from 'react';
import { Loading } from 'src/components/loading/Loading';
type Props = {
  isLoading: boolean;
};

export const FullLoading: FC<Props> = () => {
  return (
    <div className="h-screen w-screen">
      <Loading isLoading />
    </div>
  );
};
